import React, { FC } from "react"
import type { HeadProps } from "gatsby"
import { PageTemplate } from '../components/pageTemplate'
import { PostHeader } from '../components/postHeader'
import { HtmlHead } from '../components/htmlHead'

const IndexPage: FC = () => {
  return (
    <PageTemplate
      nextLink="/post_ukonceni_zkusebni_doby"
    >
      <PostHeader
        title="Konec nejstaršího serveru v ČR, DKTechu"
        link="/"
        author="7framm"
        date="14.07.2014"
        time="16:35"
        category="Oznámení"
      />
      <p>O konci DKTechu se v zákulisí mluví už dlouhé měsíce. Vždy přišel nějaký závažný problém. Během posledních několika týdnů je to hosting a licence na windows. Vždy se najde nějaké to řešení. Chce to čas, snahu, známé &#8230;. a spoustu odhodlání něco pro to udělat. Opět se vše na nějaký čas vyřešilo. Bohužel to co server byl schopen a zbytek týmu ochotno nabídnout, už většině hráčů nestačí a to se odráží na počtu postav ve hře. Je to pochopitelné a je třeba to přijmout jako fakt.</p>
      <p>Nebylo to snadné, i pro nas server mnoho znamená, ale rozhodli jsme se server ukončit.</p>
      <p><del>Dne 21.7.</del> bude naposledy možné připojit se na server DKTech a pak ho necháme již odpočívat.</p>
      <p>Snad máte krásné vzpomínky, které přetrvají. Snad jste si za tu dobu, kdy server běžel vytvořili přátele, kteří přetrvají. Byla období krásná i krušná, ale prostě nadešel čas konce. Všem kdo kdy se serverem něco měli společného, ať už jako hráči, GMka, moderátoři, admini či něco jiného, přejeme mnoho úspěchů v jiných hrách, na jiných serverech a hlavně v reálném životě. Mějte se moc krásně, my určitě na DKTech nikdy nezapomeneme.</p>
      <p><strong>Edit 20.7.2014:</strong></p>
      <div>Bíla smrt nám sežrala server a všechny, kteří se přišli rozloučit. Probíhá ukládání do hrobů, prosíme o minutu ticha za padlé.</div>
      <div></div>
      <div><a href="https://www.facebook.com/photo.php?fbid=816652678368580&amp;set=gm.10153517683118539&amp;type=1&amp;theater" target="_blank">https://www.facebook.com/photo.php?fbid=816652678368580&amp;set=gm.10153517683118539&amp;type=1&amp;theater</a></div>
      <div></div>
      <div>Bylo to úmyslně, bohužel windows, poběží sice i zítra, ale až dnes to oznámil přesně na hodiny a není možné zálohování a vše ostatní udělat jak jsme původně chtěli. Proto na závěr rozloučení jsme server úmyslně přetížili.</div>
      <div>Ještě jednou přejeme mnoho zdaru, bylo to tu fajn.</div>
      <p>
        <iframe width="500" height="375" src="https://www.youtube.com/embed/jSyp5TholCQ" title="Lineage - Requiem for GamePark" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </p>
      <p>
        <iframe width="500" height="375" src="https://www.youtube.com/embed/A6swaAy36lo" title="Happy man-Lineage II-aneb štastnej chlap L2" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </p>

      
      <div className="cleared">&nbsp;</div>
    </PageTemplate>
  )
}

export default IndexPage

export function Head() {
  return (
    <HtmlHead title="Úvodní stránka" />
  )
}